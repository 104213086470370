import React from 'react'

import { CoverageQuestionDetail, CoverageQuestions } from '../models'

export const INSURANCE_CONTRACT_DETAILS: CoverageQuestionDetail = {
  icon: (
    <i className="icon-calendar-duotone-40">
      <span className="path1" />
      <span className="path2" />
    </i>
  ),
  id: CoverageQuestions.INSURANCE_CONTRACT,
  title: (
    <>
      O seguro de vida é pra sempre?
      <br /> Posso cancelar?
    </>
  ),
  content: [
    {
      icon: 'icon-policy-cancel',
      title: 'Cancelamento de apólice',
      description:
        'Você pode cancelar o seu seguro a qualquer momento, sem permanência mínima ou multas. É só chamar o nosso time de atendimento por telefone, e-mail ou WhatsApp.',
    },
    {
      icon: 'icon-policy-reduce',
      title: 'Redução de cobertura',
      description:
        'Mas lembre-se que você pode reduzir as suas coberturas ou do capital segurado a qualquer momento.',
    },
  ],
}

export const COVERAGE_VALUE_DETAILS: CoverageQuestionDetail = {
  icon: (
    <i className="icon-currency-duotone-40">
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </i>
  ),
  id: CoverageQuestions.COVERAGE_VALUE,
  title: 'O valor de uma cobertura aumenta?',
  content: [
    {
      icon: 'icon-compare',
      title: 'Reajuste',
      description:
        'Os valores são reajustados anualmente de acordo com o Índice de Preços ao Consumidor Amplo (IPCA) e data de aniversário da sua apólice, seguindo os valores da tabela que consta nas Condições Gerais, que são disponibilizadas para o segurado ou segurada.',
    },
    {
      icon: 'icon-policy-reduce',
      title: 'Redução de cobertura',
      description:
        'Em caso de redução de cobertura, ou mesmo capital segurado, o valor do prêmio também pode sofrer variações. ',
    },
  ],
}

export const INSURANCE_PAYMENT_DETAILS: CoverageQuestionDetail = {
  icon: (
    <i className="icon-hands-shield-duotone-40">
      <span className="path1" />
      <span className="path2" />
    </i>
  ),
  id: CoverageQuestions.INSURANCE_PAYMENT,
  title: 'Como ter a certeza que vou receber o seguro de vida?',
  description:
    'Somos  a única empresa de seguro de vida que vai além para te dar mais segurança com a sua apólice.',
  content: [
    {
      icon: 'icon-search',
      title: 'Consulta de CPF',
      description:
        'A Azos faz consulta mensal do CPF de seus segurados e seguradas. Dessa forma, se o CPF não estiver mais ativo na base da receita ou de cartórios, entramos em contato com os beneficiários para garantir o pagamento do seguro, mesmo que a família não saiba da existência da apólice. ',
    },
    {
      icon: 'icon-guardian',
      title: 'Seus gaurdiões',
      description: (
        <>
          Além disso, nós temos um serviço exclusivo do Guardião, mais uma
          garantia de que, caso os beneficiários precisem, o seguro será
          acionado. <br /> O guardião não terá acesso às informações do seu
          seguro, apenas o necessário para nos acionar em caso de imprevisto.
        </>
      ),
    },
    {
      icon: 'icon-briefcase',
      title: 'Corretor sempre ao seu lado',
      description:
        'Se você  adquiriu o seu seguro Azos com corretores parceiros, o seu guardião pode entrar em contato com ele ou ela para ajudar a acionar a sua indenização. ',
    },
  ],
}

export const COVERAGE_QUESTIONS_LIST: CoverageQuestionDetail[] = [
  INSURANCE_CONTRACT_DETAILS,
  COVERAGE_VALUE_DETAILS,
  INSURANCE_PAYMENT_DETAILS,
]
