import { css, Modal, styled, Utils } from '@azos/shared'

type RootProps = {
  $isLoadingOrError: boolean
  $backgroundUrl?: string
}

export const Root = styled(
  Modal,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $isLoadingOrError, $backgroundUrl }) => css`
    .policy-coverage-details {
      &__empty-state-wrapper {
        ${$isLoadingOrError
          ? css`
              height: 100%;
            `
          : ''}
      }

      &__empty-state {
        height: 100%;
      }

      &__wrapper {
        position: relative;
        background: ${theme.palette.neutral[50]};
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__exit-button {
        position: fixed;
        top: 32px;
        right: 24px;
        display: grid;
        place-items: center;
        width: 48px;
        height: 48px;

        cursor: pointer;

        border-radius: 50%;
        background: ${theme.palette.neutral[100]};

        i {
          font-size: 1.5rem;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing[5]}px ${theme.spacing[4]}px;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: fixed;
        z-index: ${theme.zIndex.notification};
        top: 0;

        &--text {
          color: ${theme.palette.neutral[50]};
        }

        &--content {
          display: flex;
          flex-direction: column;
          justify-content: end;
          height: 100%;
          width: 100%;
          padding: ${theme.spacing[4]}px ${theme.spacing[3]}px;

          ${$backgroundUrl
            ? css`
                max-width: 100%;
                height: 350px;
                aspect-ratio: 16/9;
                background: no-repeat
                    linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0) 14.04%,
                      #000000 100%
                    ),
                  url(${$backgroundUrl}) center;
                background-size: cover;
              `
            : ''}

          &-title,
          &-description,
          &-icon {
            color: ${theme.palette.neutral[50]};
          }

          &-icon {
            font-size: 2.5rem;
          }

          &-title {
            margin-top: ${theme.spacing[3]}px;
          }

          &-description {
            margin-top: ${theme.spacing[2]}px;
          }
        }

        &--button {
          display: grid;
          place-items: center;
          width: 48px;
          height: 48px;

          cursor: pointer;

          border-radius: 50%;
          background: ${theme.palette.neutral[50]};

          i {
            font-size: 1.5rem;
          }
        }
      }

      &__content {
        padding: ${theme.spacing[4]}px;
        flex: 1;

        & > * + * {
          margin-top: ${theme.spacing[2]}px;
        }

        &--card {
          border-radius: 0;
          background: ${theme.palette.neutral[100]};
          display: flex;
          flex-direction: column;

          &-header {
            display: flex;
            align-items: center;

            & > * + * {
              margin-left: ${theme.spacing[3]}px;
            }
          }

          &-icon {
            font-size: 1.5rem;
          }

          &-description {
            display: block;
            margin-top: ${theme.spacing[2]}px;
          }
        }
      }

      &__redirect {
        background: transparent;
        display: flex;
        align-items: center;
        margin-bottom: ${theme.spacing[4]}px;
        cursor: pointer;

        &-text {
          color: ${theme.palette.primary.main};
          text-decoration: underline;
        }

        &-icon {
          margin-left: ${theme.spacing[2]}px;
          color: ${theme.palette.primary.main};
          font-size: 1.5rem;
        }
      }

      &__footer {
        position: sticky;
        bottom: 0px;

        width: 100%;
        background: ${theme.palette.neutral[50]};
        z-index: ${theme.zIndex.notification};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        padding: ${theme.spacing[4]}px;
      }
    }
  `,
)
