import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

type CoverageDetailsProps = {
  $isLoadingOrError: boolean
}

export const Root = styled(
  'div',
  Utils.transient.transientStyleProps,
)<CoverageDetailsProps>(
  ({ theme, $isLoadingOrError }) => css`
    ${$isLoadingOrError
      ? css`
          background: ${theme.palette.neutral[50]};
          height: 100%;
        `
      : css`
          background: ${theme.palette.neutral[50]};
        `}

    > .empty-state-wrapper {
      display: flex;
      place-items: center;
      height: 100%;
      justify-content: center;
    }

    .modal-coverage-details {
      position: relative;

      &__image {
        width: 100%;
      }

      &__close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 38px;
        height: 38px;
        background: ${theme.palette.neutral[50]};
        display: grid;
        place-items: center;
        cursor: pointer;

        i {
          font-size: 1.5rem;
        }
      }

      h5 {
        color: #9aa2b2;
        font-size: 14px;
        margin-bottom: 12px;
      }

      &__content {
        padding: 16px;

        i {
          font-size: 30px;
          margin-right: 12px;
        }

        &--title {
          display: flex;
          color: #a3aab9;
        }

        &--description {
          margin: 24px 0 32px 0;
        }

        &--coverage-details {
          margin-bottom: 32px;

          i {
            font-size: 24px;
            color: #a3aab9;
          }

          &__description {
            margin-top: 12px;
            & > * {
              display: inline;
            }
          }
        }

        &--coverage-grace-period {
          i {
            font-size: 24px;
            color: #a3aab9;
          }

          &__description {
            & > * {
              display: inline;
            }
          }
        }

        &--functionalities {
          margin: 32px 0;

          i {
            font-size: 24px;
            color: #a3aab9;
          }

          &__description {
            margin-top: 12px;

            & > * {
              display: inline;
            }
          }
        }

        &--coverage-link {
          margin: 32px 0;
          display: flex;
          flex-direction: column;

          i {
            margin-left: 12px;
            font-size: 18px;
            color: #a3aab9;
          }

          &__description {
            margin-top: 12px;
            cursor: pointer;
          }
        }
      }
    }

    .modal-coverage-details__button {
      button {
        i {
          font-size: 1.5rem;
          margin-right: 10px;
        }

        .icon-arrow-left {
          color: ${theme.palette.neutral[300]};
        }
      }

      button:first-of-type {
        margin: 40px 0 12px 0;
      }
    }
  `,
)
