import { css, Modal, styled } from '@azos/shared'

export const Root = styled(Modal)(
  ({ theme }) => css`
    .azos-media-card {
      &__header {
        position: sticky;
        top: 0;
        background: ${theme.palette.neutral[50]};

        &-button {
          display: flex;
          justify-content: end;
          padding: ${theme.spacing[3]}px;

          .exit-button {
            cursor: pointer;
            background: ${theme.palette.neutral[100]};
            border: 0;
            border-radius: 50%;
            padding: 0;
            position: relative;
            height: 40px;
            width: 40px;
            color: ${theme.palette.neutral[400]};

            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 1.5rem;
            }
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          padding: 0 ${theme.spacing[3]}px ${theme.spacing[5]}px;

          i {
            font-size: 2.5rem;

            span {
              font-family: 'azos-icons';
              all: unset;
            }
          }

          &--description {
            margin-top: ${theme.spacing[2]}px;
          }
        }
      }

      &__content {
        padding: ${theme.spacing[3]}px;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[2]}px;

        &--item {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing[2]}px;

          cursor: pointer;
          background: ${theme.palette.neutral[100]};
          padding: ${theme.spacing[3]}px;

          &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2px;

            img {
              max-height: 20px;
              object-fit: cover;
            }

            i {
              font-size: 1.5rem;
              color: ${theme.palette.neutral[300]};
            }
          }
        }
      }

      &__footer {
        position: sticky;
        bottom: 0px;

        width: 100%;
        background: ${theme.palette.neutral[50]};
        z-index: ${theme.zIndex.notification};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        padding: ${theme.spacing[4]}px;
      }
    }
  `,
)
