import React from 'react'

import { useAuth } from '@azos/account'
import { makeGetCoverageDetailsService } from '@main/factories/services'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import PolicyCoverageDetails from './PolicyCoverageDetails'
import { PolicyCoverageDetailsAdapterProps } from './PolicyCoverageDetails.props'

const getCoverageDetailsService = makeGetCoverageDetailsService()

const PolicyCoverageDetailsAdapter: React.VFC<
  PolicyCoverageDetailsAdapterProps
> = props => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()

  return (
    <PolicyCoverageDetails
      {...props}
      user={user}
      isGuardian={isGuardian}
      getCoverageDetailsService={getCoverageDetailsService}
    />
  )
}

export default PolicyCoverageDetailsAdapter
