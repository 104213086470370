import { azosLinks } from '@main/config/contact'

import {
  AzosContactMediaContent,
  AzosContactSlug,
  AzosContactType,
  AzosNotInsuredContent,
  ContentsSlug,
  ContentsType,
  CoverageQuestions,
} from '../models'

export const AZOS_MEDIA_CONTENT: AzosContactMediaContent[] = [
  {
    logo: 'https://storage.googleapis.com/azos-cms-strapi-uploads/globo_logo_82bf2b661f/globo_logo_82bf2b661f.png',
    title:
      'Insuretech Azos levanta R$ 55 milhões para democratizar o seguro de vida',
    description:
      'Insurtech que comercializa e cria seguros de vida alternativos, a Azos é a mais nova investida do Prosus (ex-Naspers), fundo que é um dos principais investidores da Movile, dona do iFood.',
    link: 'https://blogs.oglobo.globo.com/capital/post/insuretech-azos-levanta-r-55-milhoes-para-democratizar-o-seguro-de-vida.html',
  },
  {
    logo: 'https://storage.googleapis.com/azos-cms-strapi-uploads/exame_logo_f98eaf0650/exame_logo_f98eaf0650.png',
    title: 'Com R$ 3 bi em capital segurado, Azos recebe aporte de R$ 55 mi',
    description:
      'Após um ano da primeira rodada de venture capital, a insurtech Azos voltou a se capitalizar. Por um bom motivo: se preparar para acelerar ainda mais o crescimento e tornar mais robusta tanto a frente tecnológica como a oferta dos produtos',
    link: 'https://exame.com/exame-in/com-r-3-bi-em-capital-segurado-azos-recebe-aporte-de-r-55-mi/',
  },
  {
    logo: 'https://storage.googleapis.com/azos-cms-strapi-uploads/estadao_logo_47e82c2ad0/estadao_logo_47e82c2ad0.png',
    title:
      'Startup Azos recebe US$ 10 milhões para ampliar a oferta de seguros de vida',
    description:
      'Startup de seguros quer lançar aplicativo para celular e conectar corretores à plataforma',
    link: 'https://link.estadao.com.br/noticias/inovacao,startup-azos-recebe-us-10-milhoes-para-ampliar-a-oferta-de-seguros-de-vida,70003912453',
  },
  {
    logo: 'https://storage.googleapis.com/azos-cms-strapi-uploads/exame_logo_f98eaf0650/exame_logo_f98eaf0650.png',
    title: 'A startup Azos quer que você dê valor à vida e gaste só R$ 5',
    description:
      'Com R$ 13 milhões captados com investidores como Maya Capital e Kaszek, a insurtech brasileira lançou seu produto no mercado em abril',
    link: 'https://exame.com/exame-in/a-startup-azos-quer-que-voce-de-valor-a-vida-e-gaste-so-r-5/',
  },
  {
    logo: 'https://storage.googleapis.com/azos-cms-strapi-uploads/forbes_logo_4414950109/forbes_logo_4414950109.png',
    title:
      'Startup lança ferramenta que calcula expectativa de vida com base na profissão',
    description:
      'Aberta ao público, ferramenta ajuda a precificar o risco na oferta do seguro de vida',
    link: 'https://forbes.com.br/forbes-tech/2021/07/startup-lanca-ferramenta-que-calcula-expectativa-de-vida-com-base-na-profissao/',
  },
]

export const NOT_INSURED_CONTENT: AzosNotInsuredContent = {
  header: [
    {
      slug: ContentsSlug.ABOUT_GUARDIAN,
      type: ContentsType.GUARDIAN,
      title: 'Qual seu papel como guardião ou guardiã?',
      description: 'Qual seu papel como guardião ou guardiã?',
      imageUrl:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_about_guardian_7307eef456/azos_about_guardian_7307eef456.png?updated_at=2022-11-29T20:37:02.260Z',
      backgroundColor: '#97006F',
    },
    {
      slug: ContentsSlug.INSURANCE_IS_FOR_ME,
      type: ContentsType.NEW_INSURED,
      title: 'Seguro é pra mim?',
      description:
        'Um seguro é o melhor jeito de cuidar do seu futuro e a gente te mostra o por que.',
      imageUrl:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_insurance_for_me_09927ff8d6/azos_insurance_for_me_09927ff8d6.png?updated_at=2022-11-22T20:57:28.575Z',
      backgroundColor: '#97006F',
    },
    {
      slug: ContentsSlug.ABOUT_INSURANCE,
      type: ContentsType.NEW_INSURED,
      title: 'Não entende segurês?',
      description:
        'Te explicamos tim-tim por tim-tim como funciona o mundo dos seguros.',
      imageUrl:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_about_insurance_d200c27941/azos_about_insurance_d200c27941.png',
      backgroundColor: '#33287F',
    },
  ],
  aboutInsurance: [
    {
      slug: ContentsSlug.INSURANCE_IS_FOR_ME,
      icon: 'icon-policy-love',
      title: 'Seguro é para mim?',
      description:
        'Um seguro é o melhor jeito de cuidar do seu futuro e a gente te mostra o por que.',
    },
    {
      slug: ContentsSlug.ABOUT_INSURANCE,
      icon: 'icon-policy-doubt',
      title: 'Não entende segurês?',
      description:
        'Te explicamos tim-tim por tim-tim como funciona o mundo dos seguros.',
    },
  ],
  coverageQuestions: [
    {
      id: CoverageQuestions.INSURANCE_CONTRACT,
      icon: 'icon-calendar',
      title: 'O seguro de vida é pra sempre? Posso cancelar?',
    },
    {
      id: CoverageQuestions.COVERAGE_VALUE,
      icon: 'icon-currency',
      title: 'O valor de uma cobertura aumenta?',
    },
    {
      id: CoverageQuestions.INSURANCE_PAYMENT,
      icon: 'icon-hand-shield',
      title: 'Como ter a certeza que vou receber o seguro de vida?',
    },
  ],
  aboutAzos: [
    {
      slug: ContentsSlug.OUR_MISSION,
      icon: 'icon-azos-fill',
      title: 'Nossa missão',
      description:
        'Estamos revolucionando o mercado de seguros com inovação e experiência.',
      imageUrl:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_our_mission_7ea4885c93/azos_our_mission_7ea4885c93.png',
      linkText: 'Conheça',
    },
    {
      slug: ContentsSlug.OUR_DIFFERENTIAL,
      icon: 'icon-guardian',
      title: 'Nosso diferencial',
      description:
        'Seguros personalizados, preços justos e aprovação em 1 dia útil.',
      imageUrl:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_our_differential_0d07c7d215/azos_our_differential_0d07c7d215.png',
      linkText: 'Saiba mais',
    },
  ],
  azosContact: [
    {
      icon: 'icon-newspaper',
      title: 'Azos na mídia',
      slug: AzosContactSlug.AZOS_MEDIA,
      type: AzosContactType.MODAL,
    },
    {
      icon: 'icon-instagram',
      title: 'Instagram',
      type: AzosContactType.LINK,
      slug: AzosContactSlug.INSTAGRAM,
      link: azosLinks.instagram,
    },
    {
      icon: 'icon-youtube',
      title: 'Youtube',
      type: AzosContactType.LINK,
      slug: AzosContactSlug.YOUTUBE,
      link: azosLinks.youtube,
    },
    {
      icon: 'icon-facebook',
      title: 'Facebook',
      type: AzosContactType.LINK,
      slug: AzosContactSlug.FACEBOOK,
      link: azosLinks.facebook,
    },
    {
      icon: 'icon-linkedin',
      title: 'Linkedin',
      type: AzosContactType.LINK,
      slug: AzosContactSlug.LINKEDIN,
      link: azosLinks.linkedin,
    },
  ],
}
