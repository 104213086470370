import React from 'react'

import { AzosContentDetails, ContentsSlug } from '../models'

export const ABOUT_INSURANCE_DETAILS: AzosContentDetails = {
  slug: ContentsSlug.ABOUT_INSURANCE,
  icon: 'icon-policy-doubt',
  title: 'Não entende segurês?',
  description:
    'Te explicamos tim-tim por tim-tim como funciona o mundo dos seguros.',
  image:
    'https://storage.googleapis.com/azos-cms-strapi-uploads/content_about_insurance_1ea5b6fa1f/content_about_insurance_1ea5b6fa1f.png',
  content: [
    {
      icon: 'icon-insurance',
      title: 'O que é cobertura?',
      text: (
        <>
          A cobertura é o que vai indicar qual é a proteção que você está
          contratando ou deseja contratar. Ela equivale ao conjunto de eventos
          (sinistros) contra os quais o segurado ou segurada quer ser
          indenizado, caso o evento relativo ao seguro ocorra.
        </>
      ),
    },
    {
      icon: 'icon-warning',
      title: 'O que é um sinistro?',
      text: (
        <>
          É quando um dos eventos para os quais você contratou uma cobertura
          acontece. Por exemplo: no seguro de vida, o falecimento do titular é
          um sinistro.
        </>
      ),
    },
    {
      icon: 'icon-currency',
      title: 'O que é prêmio?',
      text: (
        <>
          É o valor que você paga para ter direito à cobertura do seguro que
          contratou.
        </>
      ),
    },
    {
      icon: 'icon-contract',
      title: 'O que são as condições gerais?',
      text: (
        <>
          Conjunto de cláusulas que regem todas as modalidades e/ou coberturas
          de um plano de seguro e, portanto, estabelecem as obrigações e os
          direitos das partes contratantes. É bom ler com atenção e cuidado.
        </>
      ),
    },
  ],
}

export const INSURANCE_IS_FOR_ME_DETAILS: AzosContentDetails = {
  slug: ContentsSlug.INSURANCE_IS_FOR_ME,
  icon: 'icon-policy-love',
  title: 'Seguro é pra mim?',
  description:
    'Um seguro é o melhor jeito de cuidar do seu futuro e a gente te mostra o por que.',
  image:
    'https://storage.googleapis.com/azos-cms-strapi-uploads/content_insurance_is_for_me_cd4f1cec4d/content_insurance_is_for_me_cd4f1cec4d.png',
  content: [
    {
      icon: 'icon-user-users',
      title: 'Quem deve ter um seguro?',
      text: (
        <>
          Seguro é para todos e todas que tenham dependentes financeiros e
          desejam que eles recebam algum valor em caso de morte.
        </>
      ),
    },
    {
      icon: 'icon-beneficiary',
      title: 'Mas para quê preciso de um seguro?',
      text: (
        <>
          Oferecemos seguros de vida acessíveis, com ótimo atendimento e
          coberturas que vão até R$2 milhões. <br />
          <br />
          <br />A gente chegou para mudar a forma como as coisas são feitas com
          um seguro feito para você:
        </>
      ),
    },
    {
      icon: 'icon-user-doubt',
      title: 'Qual é o seguro certo para mim?',
      text: (
        <>
          A melhor forma de saber qual é o seguro certo para o seu perfil é
          simulando e contando com o suporte de um corretor ou corretora
          parceiro da Azos.
        </>
      ),
    },
    {
      icon: 'icon-proposal',
      title: 'Como contratar um seguro?',
      text: (
        <>
          Através da simulação, que é feita de forma rápida e simples, você
          preenche informações que vão te ajudar a encontrar coberturas
          personalizadas e ideais!
        </>
      ),
    },
    {
      icon: 'icon-user-heart',
      title: 'Posso contratar o seguro para a minha família? ',
      text: (
        <>
          A cobertura é o que vai indicar qual é a proteção que você está
          contratando ou deseja contratar. Ela equivale ao conjunto de eventos
          (sinistros) contra os quais o segurado ou segurada quer ser
          indenizado, caso o evento relativo ao seguro ocorra.
        </>
      ),
    },
  ],
}

export const OUR_DIFFERENTIAL_DETAILS: AzosContentDetails = {
  slug: ContentsSlug.OUR_DIFFERENTIAL,
  icon: 'icon-guardian',
  title: 'Nosso diferencial',
  description:
    'Seguros personalizados, preços justos e aprovação em 1 dia útil.',
  image:
    'https://storage.googleapis.com/azos-cms-strapi-uploads/content_azos_our_differential_0944979919/content_azos_our_differential_0944979919.png',
  content: [
    {
      icon: 'icon-shield',
      title: 'Finalmente um seguro para a sua vida',
      text: (
        <>
          Nos preocupamos com o futuro e o legado de nossos clientes. Sabe
          aquele papo de seguro de vida é inacessível, tem letras miúdas e a
          cobertura é sempre duvidosa? Esquece.
        </>
      ),
    },
    {
      icon: 'icon-onboarding',
      title: 'Sem burocracia e com preço justo',
      text: (
        <>
          Oferecemos seguros de vida acessíveis, com ótimo atendimento e
          coberturas que vão até R$2 milhões. <br />
          <br />
          <br />A gente chegou para mudar a forma como as coisas são feitas com
          um seguro feito para você:
        </>
      ),
    },
  ],
  descriptionList: [
    {
      icon: 'icon-fast',
      text: 'A contração é rápida, prática e fácil',
    },
    {
      icon: 'icon-onboarding',
      text: 'Seguro aprovado em 30 segundos ou até 1 dia útil',
    },
    {
      icon: 'icon-insurance',
      text: 'Coberturas com preços justos',
    },
    {
      icon: 'icon-policy-shield',
      text: 'Coberturas independentes e de até R$2 milhões',
    },
    {
      icon: 'icon-headphone',
      text: 'Atendimento de qualidade',
    },
    {
      icon: 'icon-star',
      text: 'Benefícios exclusivos para clientes Azos',
    },
    {
      icon: 'icon-briefcase',
      text: 'Corretores parceiros altamente qualificados para o seu suporte',
    },
    {
      icon: 'icon-guardian',
      text: 'Nomeação do Guardião',
    },
  ],
  footer: (
    <>
      E muito mais! <br />
      <br />
      <br /> A Azos é o melhor jeito de cuidar do seu futuro.{' '}
    </>
  ),
}

export const OUR_MISSION_DETAILS: AzosContentDetails = {
  slug: ContentsSlug.OUR_MISSION,
  icon: 'icon-logo',
  title: 'Nossa missão',
  description:
    'Estamos revolucionando o mercado de seguros com inovação e experiência.',
  image:
    'https://storage.googleapis.com/azos-cms-strapi-uploads/content_azos_our_mission_6e4c883a40/content_azos_our_mission_6e4c883a40.png',
  content: [
    {
      icon: 'icon-goal',
      title: 'Queremos proteger os sonhos das famílias brasileiras',
      text: (
        <>
          Se todo mundo precisa de um seguro, nós chegamos para desburocratizar
          esse acesso e revolucionar a forma como as coisas são feitas neste
          mercado. <br />
          <br />
          <br />E para isso, facilitar o acesso ao seguro de vida para todos e
          todas, independente de gênero, cor ou etnia, hobbie e classe social,
          através da inovação e tecnologia. <br />
          <br />
          <br /> Por isso, buscamos simplificar a experiência e mudar como o
          segmento é visto na vida dos brasileiros e brasileiras que desejam que
          os seus sonhos não sejam interrompidos.
        </>
      ),
    },
    {
      icon: 'icon-show',
      title: 'Segurança e transparência',
      text: (
        <>
          Somos autorizados pela Susep, órgão oficial responsável pelo controle
          e fiscalização dos mercados de seguro.
        </>
      ),
    },
    {
      icon: ' icon-guardian',
      title: 'Nossos investidores',
      text: (
        <>
          Recebemos mais de R$100 milhões em aportes financeiros para entregar a
          melhor experiência para você.
        </>
      ),
    },
  ],
  imageContents: [
    {
      image:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/munich_re_investor_d424d26566/munich_re_investor_d424d26566.png',
      title: 'Munich RE',
    },
    {
      image:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/kaszek_investor_19e9450fc6/kaszek_investor_19e9450fc6.png',
      title: 'Kaszek',
    },
    {
      image:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/maya_capital_investor_eb30288d1c/maya_capital_investor_eb30288d1c.png',
      title: 'Maya Capital',
    },
    {
      image:
        'https://storage.googleapis.com/azos-cms-strapi-uploads/propel_investors_51ba82b0bb/propel_investors_51ba82b0bb.png',
      title: 'Propel Venture',
    },
  ],
}

export const ABOUT_AZOS_CONTENT_LIST: AzosContentDetails[] = [
  OUR_MISSION_DETAILS,
  OUR_DIFFERENTIAL_DETAILS,
  INSURANCE_IS_FOR_ME_DETAILS,
  ABOUT_INSURANCE_DETAILS,
]

export const OTHER_CONTENTS_LIST = [
  {
    slug: ContentsSlug.OUR_MISSION,
    icon: 'icon-azos-fill',
    title: 'Nossa missão',
    description:
      'Estamos revolucionando o mercado de seguros com inovação e experiência.',
    imageUrl:
      'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_our_mission_7ea4885c93/azos_our_mission_7ea4885c93.png',
    linkText: 'Saiba mais',
  },
  {
    slug: ContentsSlug.OUR_DIFFERENTIAL,
    icon: 'icon-guardian',
    title: 'Nosso diferencial',
    description:
      'Seguros personalizados, preços justos e aprovação em 1 dia útil.',
    imageUrl:
      'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_our_differential_0d07c7d215/azos_our_differential_0d07c7d215.png',
    linkText: 'Saiba mais',
  },
  {
    slug: ContentsSlug.INSURANCE_IS_FOR_ME,
    icon: 'icon-policy-love',
    title: 'Seguro é para mim?',
    description:
      'Um seguro é o melhor jeito de cuidar do seu futuro e a gente te mostra o por que.',
    imageUrl:
      'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_insurance_for_me_mini_c1eb634941/azos_insurance_for_me_mini_c1eb634941.png',
    linkText: 'Saiba mais',
  },
  {
    slug: ContentsSlug.ABOUT_INSURANCE,
    icon: 'icon-policy-doubt',
    title: 'Não entende segurês?',
    description:
      'Te explicamos tim-tim por tim-tim como funciona o mundo dos seguros.',
    imageUrl:
      'https://storage.googleapis.com/azos-cms-strapi-uploads/azos_about_insurance_mini_c0b0f34219/azos_about_insurance_mini_c0b0f34219.png',
    linkText: 'Saiba mais',
  },
]
