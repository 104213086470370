import { css, styled, Utils } from '@azos/shared'

type HeaderImageProps = {
  $backgroundImage: string
  $backgroundColor: string
}

export const Root = styled('div')(
  ({ theme }) => css`
    .empty-state-wrapper {
      height: 100vh;
      display: grid;
      place-items: center;
    }

    & .container {
      max-width: 100% !important;
    }

    .page-header__wrapper {
      padding: ${theme.spacing[4]}px 0 0 !important;
    }

    .not-insured-home {
      &__page-header-content {
        padding-top: ${theme.spacing[4]}px;

        @media (min-width: ${theme.breakpoints.md}px) {
          padding-left: ${theme.spacing[4]}px;
          padding-bottom: ${theme.spacing[4]}px;
        }
      }

      &__content {
        margin-bottom: 62px;
      }

      &__user-content {
        display: flex;

        &--photo {
          margin-left: ${theme.spacing[4]}px;

          @media (min-width: ${theme.breakpoints.md}px) {
            margin-left: 0;
          }
        }

        &--text {
          margin-left: ${theme.spacing[3]}px;
          display: flex;
          flex-direction: column;
        }

        &--user-name {
          color: ${theme.palette.neutral[50]};
        }

        &--user-email {
          color: ${theme.palette.neutral[300]};
        }
      }

      &__header {
        margin-top: ${theme.spacing[4]}px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ${theme.spacing[4]}px;

        &--image {
          height: 196px;
          cursor: pointer;
        }

        @media (min-width: ${theme.breakpoints.md}px) {
          flex-direction: row;
          flex-wrap: wrap;
          margin: ${theme.spacing[4]}px 0 0;
          align-items: center;

          &--image {
            max-width: 449px;
          }
        }
      }

      &__content {
        padding: 0 ${theme.spacing[4]}px;
      }

      &__text {
        color: ${theme.palette.primary.main};
      }

      &__products {
        margin-bottom: 42px;

        &-header {
          & > * + * {
            margin-left: 4px;
          }

          & > * {
            display: inline;
          }
        }

        &-content {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;

          gap: ${theme.spacing[2]}px;

          &--item {
            width: 100%;
            transition: box-shadow 0.3s;

            &:hover {
              box-shadow: ${theme.shadow[300]};
            }

            @media (min-width: ${theme.breakpoints.md}px) {
              max-width: 248px;
            }
          }
        }
      }

      &__about-insurance {
        &-header {
          & > * + * {
            margin-left: ${theme.spacing[1]}px;
          }

          & > * {
            display: inline;
          }
        }

        &-content {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing[2]}px;
          margin-bottom: 42px;

          &--header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            i {
              font-size: 1.5rem;

              &.link {
                color: ${theme.palette.neutral[300]};
              }
            }
          }

          &--item {
            width: 100%;
            background: ${theme.palette.neutral[50]};
            padding: ${theme.spacing[3]}px;
            transition: box-shadow 0.3s;
            cursor: pointer;

            @media (min-width: ${theme.breakpoints.md}px) {
              max-width: 248px;
            }
            &:hover {
              box-shadow: ${theme.shadow[300]};
            }
          }
        }
      }

      &__coverage-questions {
        &-header {
          & > * + * {
            margin-left: ${theme.spacing[1]}px;
          }

          & > * {
            display: inline;
          }
        }

        &-content {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing[2]}px;

          &--header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            i {
              font-size: 1.5rem;

              &.link {
                color: ${theme.palette.neutral[300]};
              }
            }
          }

          &--item {
            width: 100%;
            background: ${theme.palette.neutral[50]};
            padding: ${theme.spacing[3]}px;
            transition: box-shadow 0.3s;
            cursor: pointer;

            @media (min-width: ${theme.breakpoints.md}px) {
              max-width: 248px;
            }

            &:hover {
              box-shadow: ${theme.shadow[300]};
            }
          }
        }
      }

      &__about-azos {
        margin-top: 42px;

        &-header {
          & > * + * {
            margin-left: ${theme.spacing[1]}px;
          }

          & > * {
            display: inline;
          }
        }

        &-content {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;

          gap: ${theme.spacing[2]}px;
        }

        &--item {
          width: 100%;
          background: ${theme.palette.neutral[50]};
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: box-shadow 0.3s;

          @media (min-width: ${theme.breakpoints.md}px) {
            max-width: 248px;
          }

          &:hover {
            box-shadow: ${theme.shadow[300]};
          }

          img {
            max-height: 144px;
            object-fit: cover;
            object-position: top;
          }

          &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            padding: ${theme.spacing[3]}px;
            flex: 1;
          }

          &-content {
            display: flex;
            flex-direction: column;

            flex: 1;

            i {
              font-size: 1.5rem;
            }
          }

          &-title {
            margin-top: 12px;
          }

          &-description {
            margin-top: ${theme.spacing[2]}px;
          }

          &-link-content {
            margin-top: 10px;
            display: flex;
            justify-content: end;
            align-items: center;

            &--text {
              text-decoration: underline;
              color: ${theme.palette.primary.main};
            }

            i {
              margin-left: 12px;
              font-size: 1.5rem;
              color: ${theme.palette.primary.main};
            }
          }
        }
      }

      &__azos-contact {
        margin-top: 42px;

        &-header {
          & > * + * {
            margin-left: ${theme.spacing[1]}px;
          }

          & > * {
            display: inline;
          }
        }

        &-content {
          margin-top: 14px;
          display: flex;
          flex-wrap: wrap;

          gap: ${theme.spacing[2]}px;

          &--header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            i {
              font-size: 1.5rem;

              &.link {
                color: ${theme.palette.neutral[300]};
              }
            }
          }

          &--item {
            width: 100%;
            max-width: 152px;
            background: ${theme.palette.neutral[50]};
            padding: ${theme.spacing[3]}px;
            cursor: pointer;
            transition: box-shadow 0.3s;

            &:hover {
              box-shadow: ${theme.shadow[300]};
            }
          }
        }
      }
    }
  `,
)

export const HeaderImage = styled(
  'div',
  Utils.transient.transientStyleProps,
)<HeaderImageProps>(
  ({ theme, $backgroundImage, $backgroundColor }) => css`
    background: url(${$backgroundImage});
    width: 100%;
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    transition: box-shadow 0.3s;

    @media (min-width: ${theme.breakpoints.md}px) {
      max-width: 449px;
    }

    &:hover {
      box-shadow: ${theme.shadow[300]};
    }

    .not-insured-home__header--image {
      &-content {
        clip-path: polygon(
          0% 0%,
          0% 100%,
          62.75% 100%,
          75% 100%,
          100% 75%,
          100% 0%
        );

        background: ${$backgroundColor};
        padding: ${theme.spacing[3]}px;

        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[1]}px;

        max-width: 232px;
        height: 156px;

        position: absolute;
        z-index: 100;
        left: 20px;
        bottom: 22px;

        & > * {
          color: ${theme.palette.neutral[50]};
        }
      }

      &-footer {
        display: none;

        i {
          margin-bottom: 8px;
          font-size: 1.5rem;
        }

        .text-link {
          display: block;
          text-decoration: underline;
        }

        & > * {
          color: ${theme.palette.neutral[50]};
        }

        position: absolute;
        right: 24px;
        bottom: 16px;

        @media (min-width: 420px) {
          display: flex;
          align-items: center;
          gap: ${theme.spacing[2]}px;
        }
      }
    }
  `,
)
