import { SimulateInsuranceClickType } from '@azos/analytics/src/domain/models'
import { CoveragesCode } from '@azos/core'

const getEventCases = (code: CoveragesCode) => {
  switch (code) {
    case CoveragesCode.COD_0001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_1001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2001_SEGURO_DE_VIDA:
    case CoveragesCode.COD_2010_SEGURO_DE_VIDA_2:
      return SimulateInsuranceClickType.LIFE_INSURANCE
    case CoveragesCode.COD_0002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_1002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_2002_ASSISTENCIA_FUNERAL:
    case CoveragesCode.COD_2016_AFI:
      return SimulateInsuranceClickType.FUNERAL_ASSISTANCE
    case CoveragesCode.COD_0004_DOENCAS_GRAVES:
      return SimulateInsuranceClickType.GENERAL_DISEASES
    case CoveragesCode.COD_0005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_1005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2005_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2011_DOENCAS_GRAVES_10:
    case CoveragesCode.COD_2015_DG30:
      return SimulateInsuranceClickType.GENERAL_DISEASES_10
    case CoveragesCode.COD_0003_INVALIDEZ:
    case CoveragesCode.COD_1003_INVALIDEZ:
    case CoveragesCode.COD_2003_INVALIDEZ:
    case CoveragesCode.COD_2013_INVALIDEZ_2:
    case CoveragesCode.COD_2014_IPTA_ESTENDIDA:
      return SimulateInsuranceClickType.ACCIDENT_DISABILITY
    case CoveragesCode.COD_0006_DIH:
    case CoveragesCode.COD_1006_DIH:
    case CoveragesCode.COD_2006_DIH:
      return SimulateInsuranceClickType.DIH
    case CoveragesCode.COD_2007_RIT:
      return SimulateInsuranceClickType.RIT
    case CoveragesCode.COD_2008_CIRURGIAS:
      return SimulateInsuranceClickType.SURGERY
    case CoveragesCode.COD_1009_SAFF:
    case CoveragesCode.COD_2009_SAFF:
    case CoveragesCode.COD_2017_AFF:
      return SimulateInsuranceClickType.RIT
    case CoveragesCode.COD_2012_MAC:
      return SimulateInsuranceClickType.MAC
  }
}

export { getEventCases }
