import { css, Modal, styled } from '@azos/shared'

export const Root = styled(Modal)(
  ({ theme }) => css`
    .modal-content {
      border-radius: 0;
      min-width: unset;
    }

    .first-access-modal {
      padding: 32px;

      i {
        font-size: 2.5rem;

        span {
          font-family: 'azos-icons';
          all: unset;
        }
      }

      &__title {
        margin-top: 14px;

        strong {
          color: ${theme.palette.primary[500]};
        }
      }

      &__content {
        margin: 24px 0 32px 0;

        span {
          color: ${theme.palette.neutral[400]};
        }
      }

      button {
        :first-of-type {
          margin-bottom: 12px;
        }
      }
    }
  `,
)
