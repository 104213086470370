export enum ContentsSlug {
  ABOUT_GUARDIAN = 'sobre-guardiao',
  INSURANCE_IS_FOR_ME = 'seguro-e-pra-mim',
  ABOUT_INSURANCE = 'sobre-seguros',
  OUR_DIFFERENTIAL = 'nosso-diferencial',
  OUR_MISSION = 'nossa-missao',
}

export enum ContentsType {
  NEW_INSURED = 'new-insured',
  GUARDIAN = 'guardian',
}

export enum CoverageQuestions {
  INSURANCE_CONTRACT = '1',
  COVERAGE_VALUE = '2',
  INSURANCE_PAYMENT = '3',
}

export enum AzosContactType {
  MODAL = 'modal',
  LINK = 'link',
}

export type AboutAzosContent = {
  slug: ContentsSlug
  icon: string
  title: string
  description: string
  imageUrl: string
  linkText: string
}

export type AzosContactMediaContent = {
  logo: string
  title: string
  description: string
  link: string
}

export enum AzosContactSlug {
  AZOS_MEDIA = 'azos-media',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
}

export type AzosNotInsuredContact = {
  icon: string
  title: string
  slug: AzosContactSlug
  type: AzosContactType
  link?: string
}

export type AzosNotInsuredContentHeader = {
  slug: ContentsSlug
  type: ContentsType
  title: string
  description: string
  imageUrl: string
  backgroundColor: string
}

export type AzosNotInsuredAboutInsurance = {
  slug: ContentsSlug
  icon: string
  title: string
  description: string
}

export type AzosNotInsuredCoverageQuestions = {
  id: CoverageQuestions
  icon: string
  title: string
}

export type AzosNotInsuredAboutAzos = {
  slug: ContentsSlug
  icon: string
  title: string
  description: string
  imageUrl: string
  linkText: string
}

export type AzosNotInsuredAzosContact = {
  icon: string
  title: string
  type: AzosContactType
  slug: AzosContactSlug
  link?: string
}

export type AzosNotInsuredContent = {
  header: AzosNotInsuredContentHeader[]
  aboutInsurance?: AzosNotInsuredAboutInsurance[]
  coverageQuestions: AzosNotInsuredCoverageQuestions[]
  aboutAzos: AzosNotInsuredAboutAzos[]
  azosContact: AzosNotInsuredAzosContact[]
}
