import React from 'react'

import { RawHtml, Spinner } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { EmptyState, Modal, withModal } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { useFetchCoverageDetails } from '@presentation/hooks/useFetchCoverageDetails'

import { ModalCoverageDetailsProps } from './ModalCoverageDetails.props'
import { Root } from './ModalCoverageDetails.styles'

const ModalCoverageDetails = ({
  open,
  onClose,
  code,
  onSelect,
  canAddCoverage,
}: ModalCoverageDetailsProps) => {
  const { isLoading, isError, data } = useFetchCoverageDetails(code)

  const handleOnClose = () => {
    if (data?.title) {
      analytics.dashboard.upsell.calculator.closeDetailsModal.execute({
        type: data.title,
      })
    }
    onClose()
  }

  const handleSelect = () => {
    if (data?.title) {
      analytics.dashboard.upsell.calculator.clickDetailsAddCoverage.execute({
        type: data.title,
      })
    }
    onSelect?.()
  }

  const handleGoBack = () => {
    if (data?.title) {
      analytics.dashboard.upsell.calculator.goBackDetailsCoverage.execute({
        type: data.title,
      })
    }
    onClose()
  }

  const handleClickLink = (url: string, isGeneralCondition?: boolean) => {
    if (data?.title && isGeneralCondition) {
      analytics.dashboard.upsell.calculator.clickGeneralConditionsLink.execute({
        type: data.title,
      })
    }

    window.open(url, '_blank')
  }

  return (
    <Modal
      variant="drawer"
      open={open}
      onClose={handleOnClose}
      noGutter={true}
      showHeader={false}
    >
      <Root $isLoadingOrError={isLoading || isError}>
        {isError && (
          <div className="empty-state-wrapper">
            <EmptyState
              align="center"
              icon="icon-policy-cancel"
              iconColor="error"
              title="Erro ao carregar o recurso"
            >
              Por favor, tente novamente ou entre em contato com nosso suporte.
            </EmptyState>
          </div>
        )}

        {isLoading && (
          <div className="empty-state-wrapper">
            <EmptyState
              align="center"
              icon={<Spinner />}
              title="Carregando cobertura"
            >
              Estamos carregando os detalhes da cobertura.
            </EmptyState>
          </div>
        )}

        {!isError && !isLoading && (
          <div className="modal-coverage-details">
            <button
              className="modal-coverage-details__close-button"
              onClick={handleOnClose}
            >
              <i className="icon-exit" />
            </button>
            <img
              src={data?.image as unknown as string}
              alt="imagem de uma família se abraçando"
              className="modal-coverage-details__image"
            />
            <div className="modal-coverage-details__content">
              <div className="modal-coverage-details__content--title">
                <i className={data?.icon} />
                <Text variant="h4">{data?.title}</Text>
              </div>
              <div className="modal-coverage-details__content--description">
                <Text variant="body2">{data?.description}</Text>
              </div>
              <div className="modal-coverage-details__content--coverage-details">
                <Text variant="h5">Nós cobrimos</Text>
                {data?.coverageDetails.map(coverageDetail => (
                  <div
                    className="modal-coverage-details__content--coverage-details__description"
                    key={coverageDetail.id}
                  >
                    <i className={coverageDetail.icon} />
                    <RawHtml tag={Text}>{coverageDetail.description}</RawHtml>
                    <br />
                  </div>
                ))}
              </div>
              <div className="modal-coverage-details__content--coverage-grace-period">
                <Text variant="h5">Carência</Text>
                {data?.coverageGracePeriod.map(gracePeriod => (
                  <div
                    className="modal-coverage-details__content--coverage-grace-period__description"
                    key={gracePeriod.id}
                  >
                    <i className={gracePeriod.icon} />
                    <RawHtml tag={Text}>{gracePeriod.description}</RawHtml>
                    <br />
                  </div>
                ))}
              </div>
              <div className="modal-coverage-details__content--functionalities">
                <Text variant="h5">Funcionalidades</Text>
                {data?.functionalities.map(functionality => (
                  <div
                    className="modal-coverage-details__content--functionalities__description"
                    key={functionality.id}
                  >
                    <i className={functionality.icon} />
                    <Text>{functionality.description}</Text>
                    <br />
                  </div>
                ))}
              </div>
              <div className="modal-coverage-details__content--coverage-value">
                <Text variant="h5">Valor da cobertura</Text>
                <Text>{data?.coverageValue}</Text>
                <br />
              </div>
              <div className="modal-coverage-details__content--coverage-link">
                <Text variant="h5">Mais detalhes</Text>
                {data?.coverageLinks.map(link => (
                  <div
                    className="modal-coverage-details__content--coverage-link__description"
                    key={link.id}
                  >
                    <Text
                      onClick={() =>
                        handleClickLink(link.url, link.isGeneralCondition)
                      }
                    >
                      {link.title}
                      <i className={link.icon as string} />
                    </Text>
                  </div>
                ))}
                <br />
              </div>
              <div className="modal-coverage-details__button">
                {canAddCoverage && (
                  <Button
                    icon="icon-add"
                    variant="primary"
                    fullWidth={true}
                    onClick={handleSelect}
                  >
                    Quero essa cobertura
                  </Button>
                )}
                <Button
                  icon="icon-arrow-left"
                  variant="outline"
                  fullWidth={true}
                  onClick={handleGoBack}
                >
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        )}
      </Root>
    </Modal>
  )
}

export default withModal(ModalCoverageDetails)
