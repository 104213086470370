import React from 'react'

import { Button, Spinner, Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  SimulateInsuranceClickSession,
  SimulateInsuranceClickType,
} from '@azos/analytics/src/domain/models'
import { EmptyState, withModal } from '@azos/shared'
import { env } from '@main/config/env'
import {
  CoverageQuestionDetail,
  CoverageQuestions,
} from '@presentation/pages/notInsuredHome/models'
import { useRouter } from 'next/router'

import { CoverageQuestionsDetailsProps } from './CoverageQuestionDetails.props'
import { Root } from './CoverageQuestionDetails.styles'

const ModalCoverageQuestions: React.VFC<CoverageQuestionsDetailsProps> = ({
  code,
  getCoverageQuestionDetails,
  open,
  user,
  isGuardian,
  onClose,
}) => {
  const router = useRouter()

  const [isError, setIsError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState<CoverageQuestionDetail | null>(null)

  const fetchCoverageDetails = React.useCallback(
    (code: CoverageQuestions) => {
      getCoverageQuestionDetails(code)
        .then(response => setData(response))
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false))
    },
    [getCoverageQuestionDetails],
  )

  React.useEffect(() => {
    if (code) {
      fetchCoverageDetails(code)
    }
  }, [code, fetchCoverageDetails])

  const handleSimulate = () => {
    const eventCases: {
      [key in CoverageQuestions]: SimulateInsuranceClickType
    } = {
      [CoverageQuestions.COVERAGE_VALUE]:
        SimulateInsuranceClickType.COVERAGE_VALUE,
      [CoverageQuestions.INSURANCE_CONTRACT]:
        SimulateInsuranceClickType.INSURANCE_CONTRACT,
      [CoverageQuestions.INSURANCE_PAYMENT]:
        SimulateInsuranceClickType.INSURANCE_PAYMENT,
    }

    if (code && eventCases[code]) {
      analytics.dashboard.home.simulateInsuranceClick.execute({
        sessao: SimulateInsuranceClickSession.COVERAGE_QUESTIONS,
        tipo: eventCases[code],
        isInsured: !!user?.isInsured,
        isGuardian,
        isNotInsuredNorGuardian: !user?.isInsured && !isGuardian,
      })
    }

    if (user?.isInsured) {
      router.push('/simular-novo-seguro')
    } else {
      router.push(env.SIMULATION_URL)
    }
  }

  return (
    <Root
      showHeader={false}
      noGutter
      open={open}
      variant="drawer"
      $isLoadingOrError={isError || isLoading}
      onClose={onClose}
    >
      {isError && (
        <div className="coverage-question-details__empty-state-wrapper">
          <EmptyState
            className="coverage-question-details__empty-state"
            align="center"
            icon="icon-policy-cancel"
            iconColor="error"
            title="Erro ao carregar o recurso"
          >
            Por favor, tente novamente ou entre em contato com nosso suporte.
          </EmptyState>
        </div>
      )}
      {isLoading && (
        <div className="coverage-question-details__empty-state-wrapper">
          <EmptyState
            className="coverage-question-details__empty-state"
            align="center"
            icon={<Spinner />}
            title="Carregando cobertura"
          >
            Estamos carregando os detalhes da cobertura.
          </EmptyState>
        </div>
      )}

      {data && (
        <div className="coverage-question-details__content">
          <div className="coverage-question-details__content--header">
            <div className="coverage-question-details__content--header-button">
              <button onClick={onClose} data-testid="exit-modal-button">
                <i className="icon-exit" />
              </button>
            </div>
            {data.icon}
            <Typography
              variant="h5"
              tag="strong"
              className="coverage-question-details__content--header-title"
            >
              {data.title}
            </Typography>
            {data.description && (
              <Typography
                variant="body3"
                className="coverage-question-details__content--header-description"
              >
                {data.description}
              </Typography>
            )}
          </div>
          <div className="coverage-question-details__content--wrapper">
            {data.content.map((productItem, index) => (
              <div
                key={index}
                className="coverage-question-details__content--card"
              >
                <i className={productItem.icon} />
                <Typography variant="bodyBold2">{productItem.title}</Typography>
                <Typography
                  variant="body3"
                  className="coverage-question-details__content--card-description"
                >
                  {productItem.description}
                </Typography>
              </div>
            ))}
          </div>

          <div className="coverage-question-details__footer">
            <Button fullWidth onClick={handleSimulate}>
              Simule um novo seguro
            </Button>
          </div>
        </div>
      )}
    </Root>
  )
}

export default withModal<CoverageQuestionsDetailsProps>(ModalCoverageQuestions)
