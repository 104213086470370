import React from 'react'

import { cloneDeep } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import { AzosNotInsuredContent, ContentsType } from './models'
import NotInsuredHome from './notInsuredHome'
import { NOT_INSURED_CONTENT } from './utils'

const NotInsuredHomeAdapter: React.VFC = () => {
  const { isGuardian, isLoading, protectedList } = useGuardian()
  const { user } = useAuth()

  const [isLoadingFetch, settIsLoadingFetch] = React.useState(true)
  const [content, setContent] =
    React.useState<AzosNotInsuredContent>(NOT_INSURED_CONTENT)

  const fetchContentByType = React.useCallback(() => {
    // TODO: Add rule when user is guardian
    // eslint-disable-next-line no-constant-condition
    if (isGuardian) {
      const guardianContent = cloneDeep(NOT_INSURED_CONTENT)

      const headerFilteredByGuardian = guardianContent.header.filter(
        item => item.type === ContentsType.GUARDIAN,
      )
      guardianContent.header = headerFilteredByGuardian

      setContent(guardianContent)
    } else {
      const newInsuredContent = cloneDeep(NOT_INSURED_CONTENT)
      const { aboutInsurance, ...params } = newInsuredContent

      const headerFilteredByNewInsured = params.header.filter(
        item => item.type === ContentsType.NEW_INSURED,
      )
      params.header = headerFilteredByNewInsured

      setContent(params)
    }
    settIsLoadingFetch(false)
  }, [isGuardian])

  React.useEffect(() => {
    fetchContentByType()
  }, [fetchContentByType])

  return (
    <NotInsuredHome
      content={content}
      isLoading={isLoading || isLoadingFetch}
      isGuardian={isGuardian}
      protectedList={protectedList ?? []}
      user={user}
    />
  )
}

export default NotInsuredHomeAdapter
