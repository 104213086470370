import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    width: 200px;

    background: ${theme.palette.neutral[50]};
    cursor: pointer;

    .redirect {
      color: ${theme.palette.neutral[300]};
    }

    .coverage-item {
      &--icon {
        font-size: 1.5rem;
      }

      &__image {
        object-fit: cover;
        max-height: 80px;
      }

      &__content {
        padding: ${theme.spacing[3]}px;
        display: flex;
        flex-direction: column;

        &-header {
          display: flex;
          justify-content: space-between;

          &--wrapper {
            display: flex;
            align-items: center;

            & > * + * {
              margin-left: ${theme.spacing[2]}px;
            }
          }
        }

        & > * + * {
          margin-top: 12px;
        }
      }

      &__description {
        margin-top: ${theme.spacing[2]}px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  `,
)
