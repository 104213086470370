import React from 'react'

import { Button, Typography } from '@Azos-Seguros/apollo'
import { makeStorageProvider } from '@azos/core'

import { FirstAccessModalProps } from './FirstAccessModal.props'
import { Root } from './FirstAccessModal.styles'

const FirstAccessModal: React.FCC<FirstAccessModalProps> = ({
  open,
  protectedList,
  onClose,
  onViewAboutGuardian,
  onViewProtected,
}) => {
  const storageProvider = makeStorageProvider()

  const handleClose = () => {
    storageProvider.set('@azos/guardianFirstAccesss', 'true')
    onClose?.()
  }

  const handleViewProtected = () => {
    onViewProtected()
    handleClose()
  }

  const handleAboutProtected = () => {
    onViewAboutGuardian()
    handleClose()
  }

  return (
    <Root
      open={open}
      onClose={handleClose}
      variant="modal"
      showCloseButton={false}
      showHeader={false}
      maxWidth="454px"
      noGutter
    >
      <div className="first-access-modal">
        <i className="icon-guardian-duotone-24">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </i>
        <div className="first-access-modal__title">
          <Typography variant="h4">
            Boas-vindas, <strong>guardião</strong> !
          </Typography>
        </div>
        <div className="first-access-modal__content">
          <Typography variant="body3">
            Parabéns, você é a escolha de confiança de um segurado(a) Azos para
            ser Guardião. Conheça mais sobre a sua missão e explore o seu espaço
            exclusivo aqui no aplicativo.
          </Typography>
        </div>
        <Button variant="primary" fullWidth onClick={handleViewProtected}>
          {protectedList.length > 1
            ? 'Ver meus protegidos'
            : 'Ver meu protegido'}
        </Button>
        <Button variant="outline" fullWidth onClick={handleAboutProtected}>
          Mais sobre o papel de um guardião
        </Button>
      </div>
    </Root>
  )
}

export default FirstAccessModal
