import React from 'react'

import { Typography, Badge } from '@Azos-Seguros/apollo'

import { CoverageItemProps } from './CoverageItem.props'
import { Root } from './CoverageItem.styles'

const CoverageItem: React.VFC<CoverageItemProps> = ({
  coverage,
  onCoverageClick,
  className,
  showDescription = false,
}) => {
  return (
    <Root
      role="button"
      onClick={() => onCoverageClick(coverage.productCode, coverage.title)}
      data-testid={`coverage-item__${coverage.productCode}`}
      className={className}
    >
      <img
        src={coverage.cardImage}
        className="coverage-item__image"
        alt={`Imagem referente à cobertura de ${coverage.title}`}
      />
      <div className="coverage-item__content">
        <div className="coverage-item__content-header">
          <div className="coverage-item__content-header--wrapper">
            <i className={`${coverage.iconName} coverage-item--icon`} />
            {coverage.isNew && <Badge variant="success">Novo</Badge>}
          </div>
          <i className="icon-arrow-topright coverage-item--icon redirect" />
        </div>

        <Typography variant="bodyBold3">{coverage.title}</Typography>

        {showDescription && (
          <Typography variant="caption" className="coverage-item__description">
            {coverage.appProductDetail?.description}
          </Typography>
        )}
      </div>
    </Root>
  )
}

export default CoverageItem
