import React from 'react'

import { useAuth } from '@azos/account'
import { COVERAGE_QUESTIONS_LIST } from '@presentation/pages/notInsuredHome'
import { CoverageQuestions } from '@presentation/pages/notInsuredHome/models'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import ModalCoverageQuestions from './CoverageQuestionDetails'
import { CoverageQuestionsDetailsAdapterProps } from './CoverageQuestionDetails.props'

const ModalCoverageQuestionsAdapter: React.VFC<
  CoverageQuestionsDetailsAdapterProps
> = props => {
  const { user } = useAuth()
  const { isGuardian } = useGuardian()

  const getCoverageQuestions = (code: CoverageQuestions) => {
    const coverageQuestion = COVERAGE_QUESTIONS_LIST.find(
      item => item.id === code,
    )

    if (coverageQuestion) return Promise.resolve(coverageQuestion)

    return Promise.reject()
  }

  return (
    <ModalCoverageQuestions
      {...props}
      user={user}
      isGuardian={isGuardian}
      getCoverageQuestionDetails={getCoverageQuestions}
    />
  )
}

export default ModalCoverageQuestionsAdapter
