import { css, Modal, styled, Utils } from '@azos/shared'

type RootProps = {
  $isLoadingOrError: boolean
}

export const Root = styled(
  Modal,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ $isLoadingOrError, theme }) => css`
    .modal-header {
      .exit-button {
        background: ${theme.palette.neutral[100]};
      }
    }

    .coverage-question-details {
      &__empty-state-wrapper {
        ${$isLoadingOrError
          ? css`
              height: 100%;
            `
          : ''}
      }

      &__empty-state {
        height: 100%;
      }

      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;

        &--header {
          display: flex;
          flex-direction: column;
          background: ${theme.palette.neutral[50]};

          position: sticky;
          top: 0;

          padding: 0 ${theme.spacing[4]}px ${theme.spacing[5]}px;

          &-button {
            padding: ${theme.spacing[3]}px ${theme.spacing[2]}px;
            display: flex;
            justify-content: end;

            button {
              display: grid;
              place-items: center;
              width: 48px;
              height: 48px;

              cursor: pointer;

              border-radius: 50%;
              background: ${theme.palette.neutral[100]};

              i {
                font-size: 1.5rem;
              }
            }
          }

          i {
            font-size: 2.5rem;

            span {
              font-family: 'azos-icons';
              all: unset;
            }
          }

          &-title {
            display: block;
            margin-top: 12px;
          }

          &-description {
            display: block;
            margin-top: ${theme.spacing[2]}px;
          }
        }

        &--wrapper {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing[5]}px;
          flex: 1;

          padding: 0 ${theme.spacing[4]}px ${theme.spacing[4]}px;
        }

        &--card {
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing[2]}px;

          i {
            font-size: 1.5rem;
          }

          &-description {
            color: ${theme.palette.neutral[400]};
          }
        }
      }

      &__footer {
        position: sticky;
        bottom: 0px;

        width: 100%;
        background: ${theme.palette.neutral[50]};
        z-index: ${theme.zIndex.notification};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
        padding: ${theme.spacing[4]}px;
      }
    }
  `,
)
