import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import {
  SimulateInsuranceClickSession,
  SimulateInsuranceClickType,
} from '@azos/analytics/src/domain/models'
import { withModal } from '@azos/shared'
import { Button } from '@azos/shared/src/components/v2'
import { env } from '@main/config/env'
import { AZOS_MEDIA_CONTENT } from '@presentation/pages/notInsuredHome'
import { useRouter } from 'next/router'

import { AzosMediaCardProps } from './AzosMediaCard.props'
import { Root } from './AzosMediaCard.styles'

const AzosMediaCard: React.VFC<AzosMediaCardProps> = ({
  open,
  onClose,
  user,
  isGuardian,
}) => {
  const router = useRouter()

  const handleSimulate = () => {
    analytics.dashboard.home.simulateInsuranceClick.execute({
      sessao: SimulateInsuranceClickSession.AZOS_MEDIA,
      tipo: SimulateInsuranceClickType.AZOS_MEDIA,
      isInsured: !!user?.isInsured,
      isGuardian,
      isNotInsuredNorGuardian: !user?.isInsured && !isGuardian,
    })

    if (user?.isInsured) {
      router.push('/simular-novo-seguro')
    } else {
      router.push(env.SIMULATION_URL)
    }
  }

  return (
    <Root
      showHeader={false}
      noGutter
      open={open}
      variant="drawer"
      onClose={onClose}
    >
      <div className="azos-media-card__header">
        <div className="azos-media-card__header-button">
          <button type="button" onClick={onClose} className="exit-button">
            <i className="icon-exit" />
          </button>
        </div>

        <div className="azos-media-card__header-content">
          <i className="icon-newspaper-duotone-40">
            <span className="path1" />
            <span className="path2" />
          </i>
          <Typography
            variant="h5"
            tag="strong"
            className="azos-media-card__header-content--description"
          >
            Azos na mídia
          </Typography>
        </div>
      </div>
      <div className="azos-media-card__content">
        {AZOS_MEDIA_CONTENT.map((content, index) => (
          <div
            className="azos-media-card__content--item"
            key={index}
            role="button"
            onClick={() => window.open(content.link, '_blank')}
          >
            <div className="azos-media-card__content--item-header">
              <img src={content.logo} alt={content.title} />
              <i className="icon-arrow-topright" />
            </div>
            <Typography variant="bodyBold3">{content.title}</Typography>
            <Typography variant="caption">{content.description}</Typography>
          </div>
        ))}
      </div>
      <div className="azos-media-card__footer">
        <Button fullWidth onClick={handleSimulate}>
          Simule um novo seguro
        </Button>
      </div>
    </Root>
  )
}

export default withModal<AzosMediaCardProps>(AzosMediaCard)
